import apiFetch from '@wordpress/api-fetch';
import {useState, useEffect} from '@wordpress/element';
import { __ } from '@wordpress/i18n';
import Dropdown from './Dropdown';
import Buttons from "./Buttons";

const Filter = ({ taxonomy, filters, taxOption, setFilters, setCurrentPage, showLabels, currentFilterDropdown, setCurrentFilterDropdown, currentFilterButtons, setCurrentFilterButtons }) => {
  const [loading, setLoading ] = useState(true);
  const [error, setError ] = useState(null);
  const [data, setData ] = useState(null);
  
  useEffect(() => {
    setLoading(true);
    apiFetch({path: `/nua/v1/search-filter/terms/${taxonomy}`})
      .then((data) => {
        setData(data);
        setLoading(false);
        // console.log('data', data)
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    return () => setData({}); //reset state on unmount
  }, []);

  // useEffect(() => {
    // console.log('filters', filters);
    // console.log('currentFilter', currentFilter);
  // }, [currentFilter]);

  const attributes = {
		items: data?.terms ? data.terms.map((term) => ({id: term.id, parent: term.parent, slug: term.slug, label: term.name, order: term.order ? term.order : 0 })) : [],
		identifier: taxonomy,
		label: data?.taxonomy ? data.taxonomy.label : '',
		selectedItems: taxOption?.display === 'dropdown' ? currentFilterDropdown : currentFilterButtons,
    showLabels: showLabels,
		handleClickItem: (item) => {
      // console.log('item', item);
      // console.log('taxOption.display', taxOption.display);
      if (taxOption.display === 'dropdown') {
        setCurrentFilterDropdown(item);
      } else if (taxOption.display === 'buttons') {
        setCurrentFilterButtons(item);
      }
      setFilters((filters) => [...filters.filter((filter) => filter.taxonomy !== taxonomy), {taxonomy: taxonomy, term: item.slug}]);
      setCurrentPage(1);
    },
		handleClickClear: () => {
      if (taxOption.display === 'dropdown') {
        setCurrentFilterDropdown(null);
      } else if (taxOption.display === 'buttons') {
        setCurrentFilterButtons(null);
      }
      setFilters((filters) => filters.filter((filter) => filter.taxonomy !== taxonomy));
      setCurrentPage(1);
		},
	};

  const components = {
		dropdown: Dropdown,
		buttons: Buttons,
	};

	return taxOption ? wp.element.createElement(components[taxOption.display], attributes) : null;
}

export default Filter;