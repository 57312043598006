const Pagination = ( {currentPage, setCurrentPage, postsPerPage, results, filterbarRef} ) => {
  if( !results ) {
    return <div className="nua-search-filter__pagination"></div>;
  }
  
  const totalPages = Math.ceil(results?.pagination?.total / postsPerPage);
  let pages = Array.from(Array(totalPages).keys()).map((page) => page + 1);
  let firstPage = 1;
  let lastPage = totalPages;

  //Truncated pagination if more than 10 pages
  if( totalPages > 10 ) {
    firstPage = currentPage <= 5 ? 1 : currentPage - 4;
    lastPage = currentPage <= 5 ? 10 : currentPage + 5 > totalPages ? totalPages : currentPage + 5;
    pages = Array.from(Array(lastPage - firstPage + 1).keys()).map((page) => page + firstPage);
  }

  const handleClick = (page) => {
    setCurrentPage(page);
    window.scrollTo({
      behavior: 'smooth',
      top: filterbarRef.current.offsetTop - 150
    });
  }

  return <div className="nua-search-filter__pagination">
    { results?.pagination?.hasPrevious ? 
      <button className="nua-search-filter__pagination__button nua-search-filter__pagination__button--previous" onClick={() => handleClick(currentPage - 1)} aria-label="Go to the previous page">
        <svg transform="rotate(180)" width="16" height="11" xmlns="http://www.w3.org/2000/svg" role="presentation">
          <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd">
            <path  d="M8.854 10 13.6 5.5 8.854 1M13.6 5.5H1.1"/>
          </g>
        </svg>
      </button> 
    : null }

    { firstPage > 1 ? <button className="nua-search-filter__pagination__button nua-search-filter__pagination__button--skip-to-first" onClick={() => handleClick(1)}>1</button> : null }
    { firstPage > 2 ? <span className="nua-search-filter__pagination__skip-ellipses">...</span> : null }

    { pages.length > 1 ? pages.map((page) => (
      <button key={page} className={currentPage === page ? 'nua-search-filter__pagination__button nua-search-filter__pagination__button--active' : 'nua-search-filter__pagination__button'} onClick={() => handleClick(page)}>{page}</button>
      )) : null }

    { lastPage < totalPages - 1 ? <span className="nua-search-filter__pagination__skip-ellipses">...</span> : null }
    { lastPage < totalPages ? <button className="nua-search-filter__pagination__button nua-search-filter__pagination__button--skip-to-last" onClick={() => handleClick(totalPages)}>{totalPages}</button> : null }

    { results?.pagination?.hasMore ? 
      <button className="nua-search-filter__pagination__button nua-search-filter__pagination__button--next" onClick={() => handleClick(currentPage + 1)} aria-label="Go to the next page">
        <svg width="16" height="11" xmlns="http://www.w3.org/2000/svg" role="presentation">
          <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd">
            <path  d="M8.854 10 13.6 5.5 8.854 1M13.6 5.5H1.1"/>
          </g>
        </svg>
      </button> 
    : null }
  </div>;
}

export default Pagination;