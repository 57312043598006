import { render } from '@wordpress/element';
import SearchFilter from './frontend/SearchFilter';

//When the window is finished loading, get all search filter blocks
window.addEventListener('load', () => {
  const rootElements = document.querySelectorAll('.nua-search-filter');
  rootElements.forEach(rootElement => {

    const options = rootElement.getAttribute('data-options') ? JSON.parse(rootElement.getAttribute('data-options')) : {};

    //Legacy code to transpose old data attributes into new options object
    //This can probably be removed in a future version
    if( Object.keys(options).length === 0 ) {
      const postTypes = rootElement.getAttribute('data-posttypes') ? JSON.parse(rootElement.getAttribute('data-posttypes')) : [];
      const taxonomies = rootElement.getAttribute('data-taxonomies') ? JSON.parse(rootElement.getAttribute('data-taxonomies')) : [];
      const postsPerPage = rootElement.getAttribute('data-postsperpage') ? parseInt(rootElement.getAttribute('data-postsperpage')) : 10;
      const baseQueryTerms = rootElement.getAttribute('data-basequeryterms') ? JSON.parse(rootElement.getAttribute('data-basequeryterms')) : [];
      const showSearch = rootElement.getAttribute('data-showsearch') ? rootElement.getAttribute('data-showsearch') === 'true' : true;
      const enableSort = rootElement.getAttribute('data-enablesort') ? rootElement.getAttribute('data-enablesort') === 'true' : true;
      const sortOptions = rootElement.getAttribute('data-sortoptions') ? JSON.parse(rootElement.getAttribute('data-sortoptions')) : [];
      const filterByPostTypes = rootElement.getAttribute('data-filterbyposttypes') ? rootElement.getAttribute('data-filterbyposttypes') === 'true' : false;
      const enableBaseQueryMeta = rootElement.getAttribute('data-enablebasequerymeta') ? rootElement.getAttribute('data-enablebasequerymeta') === 'true' : false;
      const baseQueryMeta = rootElement.getAttribute('data-basequerymeta') ? JSON.parse(rootElement.getAttribute('data-basequerymeta')) : { relation: 'AND', queries: [] };
      const enableMetaSearch = rootElement.getAttribute('data-enablemetasearch') ? rootElement.getAttribute('data-enablemetasearch') === 'true' : false;
      const metaSearch = rootElement.getAttribute('data-metasearch') ? JSON.parse(rootElement.getAttribute('data-metasearch')) : [];

      options.postTypes = postTypes;
      options.taxonomies = taxonomies;
      options.postsPerPage = postsPerPage;
      options.baseQueryTerms = baseQueryTerms;
      options.showSearch = showSearch;
      options.enableSort = enableSort;
      options.sortOptions = sortOptions;
      options.filterByPostTypes = filterByPostTypes;
      options.enableBaseQueryMeta = enableBaseQueryMeta;
      options.baseQueryMeta = baseQueryMeta;
      options.enableMetaSearch = enableMetaSearch;
      options.metaSearch = metaSearch;
    }

    render(
      <SearchFilter options={options} />, rootElement);
  });
});