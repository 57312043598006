import parse from 'html-react-parser';
import { __ } from '@wordpress/i18n';

const Results = ( {loading, error, results} ) => {
  // console.log('results', results);

  if(loading) {
    return <div className="nua-search-filter__results"><div className="nua-search-filter__results-loading">{ nsf.strings.loading }</div></div>;
  }
  if(error) {
    return <div className="nua-search-filter__results"><div className="nua-search-filter__results-error">{nsf.strings.error} {error.message}</div></div>;
  }

  return (
    <div  className="nua-search-filter__results">
      { results?.data?.length > 0 ? results.data.map((result) => <div className="nua-search-filter__result" key={result.id}> { parse(result.cardHTML ) }</div>) : <div className="nua-search-filter__results-empty">{nsf.strings.no_results}</div> }
    </div>
  )
}

export default Results;