import { __ } from '@wordpress/i18n';
const Count = ({results, enableCustomCountLabel, customCountLabel}) => {
  const count = results ? results.pagination.total : '';

	if(!enableCustomCountLabel) {
		return (
			<div className="nua-search-filter__count"> 
				<span className="nua-search-filter__count-label">{ nsf.strings.results }</span>
				<span className="nua-search-filter__count-seperator">:&nbsp;</span> 
				<span className="nua-search-filter__count-number">{ count }</span> 
			</div>
		)
	} else {
		return (
			<div className="nua-search-filter__count"> 
				<span className="nua-search-filter__count-number">{ count }</span> 
				<span className="nua-search-filter__count-seperator">&nbsp;</span> 
				<span className="nua-search-filter__count-label">{ customCountLabel }</span>
			</div>
		)
	}
}

export default Count;