import apiFetch from '@wordpress/api-fetch';
import {useState, useEffect} from '@wordpress/element';
import { __ } from '@wordpress/i18n';
import Dropdown from './Dropdown';
import Buttons from "./Buttons";

const FilterPostTypes = ( { setPostTypes, basePostTypes, setCurrentPage, postTypesOptions, showLabels } ) => {
  const [currentFilter, setCurrentFilter] = useState(null);
  const [isActive, setIsActive ] = useState(false);
  const [loading, setLoading ] = useState(true);
  const [error, setError ] = useState(null);
  const [data, setData ] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    apiFetch({path: `/nua/v1/search-filter/post-types`},{signal: abortController.signal})
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    return () => setData({});//Reset state on unmount
  }, []);

  if( loading ){
    return (
      <div className="nua-search-filter__filter">
        <div className="nua-search-filter__filter-title">Loading...</div>
      </div>
    );
  }

  if( error ){
    return (
      <div className="nua-search-filter__filter">
        <div className="nua-search-filter__filter-title">{error.message}</div>
      </div>
    );
  }

  const availablePostTypes = data.filter((postType) => basePostTypes.includes(postType.name));

  const attributes = {
		items: availablePostTypes && availablePostTypes.length > 0 ? availablePostTypes.map((postType) => ({slug: postType.name, label: postType.label})) : [],
		identifier: "post-types",
		label: nsf.strings.type,
		selectedItems: currentFilter,
    showLabels: showLabels,
		handleClickItem: (item) => {
      setCurrentFilter(item);
      setPostTypes([item.slug]);
      setCurrentPage(1);
    },
		handleClickClear: () => {
      setCurrentFilter(null);
      setPostTypes(basePostTypes);
      setCurrentPage(1);
		},
	};

  const components = {
		dropdown: Dropdown,
		buttons: Buttons,
	};

	return postTypesOptions.display ? wp.element.createElement(components[postTypesOptions.display], attributes) : null;

  // return(
  //   <Dropdown
  //     loading={loading}
  //     error={error}
  //     items={availablePostTypes && availablePostTypes.length > 0 ? availablePostTypes.map((postType) => ({slug: postType.name, label: postType.label})) : []}
  //     identifier="post-types"
  //     label={nsf.strings.type}
  //     selectedItem={currentFilter}
  //     handleClickItem={ (item) => {
  //       setCurrentFilter(item);
  //       setPostTypes([item.slug]);
  //       setCurrentPage(1);
  //     } }
  //     handleClickClear={() => {
  //       setCurrentFilter(null);
  //       setPostTypes(basePostTypes);
  //       setCurrentPage(1);
  //     }}
  //   />
  // )
};

export default FilterPostTypes;
