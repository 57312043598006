import { __ } from '@wordpress/i18n';

const Buttons = ({items, identifier, label, showLabels, selectedItems, handleClickItem, handleClickClear }) => {
  const isSelected = selectedItems && items.some((item) => item.slug === selectedItems.slug );

  return(
    <div className={`nua-search-filter__buttons nua-search-filter__buttons--${identifier}` }>
      { showLabels ? <div className="nua-search-filter__buttons-title" tabIndex="0" >{ label }</div> : null}
      <div className="nua-search-filter__buttons-list" id={`filter--${identifier}`}>
        <button className={`nua-search-filter__buttons-item ${ !isSelected ? 'nua-search-filter__buttons-item--selected' : '' }`} onClick={() => handleClickClear()}>{nsf.strings.all}</button>
        {items && items.length > 0 ? items.sort((a, b) => a.order - b.order).map((item) => {
          // const isSelected = null
          //TODO: Fix this
          // need to figure out what the selectedItems is 
          return(
          <div 
            key={item.id} 
            className={`nua-search-filter__buttons-item ${ selectedItems && selectedItems.slug === item.slug ? 'nua-search-filter__buttons-item--selected' : '' }`}
            onKeyDown={(e) => { if( e.code === "Space" ){ e.preventDefault(); handleClickItem(item); } }}
            onClick={() => { handleClickItem(item); }}>
              {item.label}
          </div>
          )}) : <div className="nua-search-filter__buttons-item nua-search-filter__buttons-item--empty">{ nsf.strings.no_terms_found }</div>}
      </div>
    </div>
  )
}

export default Buttons;
