import { useState, useRef, useEffect } from '@wordpress/element';
import { __ } from '@wordpress/i18n';
import { ReactComponent as ChevronIcon } from '../../assets/ico-chevron.svg';

const Dropdown = ({ loading = false, error = null, items, identifier, label, selectedItems, handleClickItem, handleClickClear }) => {
  const [isActive, setIsActive ] = useState(false);

  // Close dropdown when clicking outside  
  const dropdownRef = useRef(null)  

  // check if user clicked outside of dropdwn
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };
  
  // listen for clicks outside of dropdown
  useEffect(() => {
    document.addEventListener('touchstart', handleClickOutside, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('touchstart', handleClickOutside, true);
        document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    // console.log('selectedItems', selectedItems)
  }, [selectedItems]);

  if( loading ){
    return (
      <div className="nua-search-filter__dropdown">
        <div className="nua-search-filter__dropdown-title">{ nsf.strings.loading }</div>
      </div>
    );
  }

  if( error ){
    return (
      <div className="nua-search-filter__dropdown">
        <div className="nua-search-filter__dropdown-title">{error.message}</div>
      </div>
    );
  }

  // starting from the available items it builds an array of elements with an additional indentedLabel
  const getIndentedItems = (items, parentId = 0, indent = '') => {
    const indentedItemsArray = [];

    for (const item of items) {
      if (item.parent === parentId) {
        const { id, label, slug } = item;
        const indentedName = indent + label;
        indentedItemsArray.push({ id, indentedLabel: indentedName, label, slug  });
        indentedItemsArray.push(...getIndentedItems(items, id, indent + '&mdash;&nbsp;'));
      }
    }
  
    return indentedItemsArray;
  };
  // if there are no child items, it returns the original array
  const indentedItems = getIndentedItems(items).length === 0 ? items : getIndentedItems(items);

  return(
    <div ref={dropdownRef} className={`nua-search-filter__dropdown nua-search-filter__dropdown--${identifier} ${ isActive ? 'nua-search-filter__dropdown--active' : ''}` }>
      
      <div 
        className="nua-search-filter__dropdown-title" 
        tabIndex="0" 
        onClick={ () => setIsActive( !isActive ) } 
        onKeyDown={ (e) => { if( e.code === "Space" ){ e.preventDefault(); setIsActive( !isActive ); } } }
        aria-controls={`filter--${identifier}`}
        aria-expanded={isActive}
        aria-haspopup="listbox"
        aria-label={label}
        role="combobox"
      >
          { selectedItems ? (
              <button 
                className="nua-search-filter__dropdown-clear" 
                aria-label="Clear selection" 
                onClick={() => handleClickClear()}>
                  {nsf.strings.clear}
              </button>
            ) : null 
          }

					{ (identifier === 'product_cat' && !selectedItems) ? nsf.strings.filter_by : null }
					{ (identifier === 'sort' && !selectedItems) ? nsf.strings.sort_by : null }

          {  selectedItems ? selectedItems.label : ''}

          <button type="submit" class="nua-search-filter__dropdown-arrow" aria-label="Filter by product">
            <ChevronIcon />
          </button>
      </div>
    <div className="nua-search-filter__dropdown-list" role="listbox" id={`filter--${identifier}`}>
    {indentedItems && indentedItems.length > 0 ? indentedItems.filter( item => item.slug !== "uncategorized" ).map((item) => (
      <div 
        key={item.slug} 
        className={`nua-search-filter__dropdown-item ${ selectedItems && selectedItems.slug === item.slug ? 'nua-search-filter__dropdown-item--selected' : '' }`}
        role="option" 
        tabIndex={ isActive ? 0 : -1 }
        aria-selected={ selectedItems && selectedItems.slug === item.slug ? true : false }
        onClick={() => { setIsActive( !isActive ); handleClickItem(item); }}
        onKeyPress={(e) => { if(e.key === "Spacebar" || e.key === " " || e.key === "Enter"){ e.preventDefault(); setIsActive( !isActive ); handleClickItem(item); } }}
        // display the indented label if it exists, otherwise the normal label
        dangerouslySetInnerHTML={{ __html: item.indentedLabel === undefined ? item.label : item.indentedLabel }} >
      </div>
    )) : <div className="nua-search-filter__dropdown-item nua-search-filter__dropdown-item--empty">{ nsf.strings.no_terms_found }</div>}
    </div>
  </div>
  )
}

export default Dropdown;


