import { __ } from '@wordpress/i18n';

const MetaSearch = ( { field, label, currentMetaSearch, setCurrentMetaSearch, setCurrentPage } ) => {
  return  <div className="nua-search-filter__keyword">
            <input type="text" 
              placeholder={label} 
              value={ currentMetaSearch.find( metaSearch => metaSearch.key === field )?.value } 
              onChange={e => { setCurrentMetaSearch( [ ...currentMetaSearch.filter( currentMetaSearchFilter => currentMetaSearchFilter.key !== field ), { key: field, value: e.target.value } ] ); setCurrentPage(1); }} 
            />
          </div>;
}

export default MetaSearch;