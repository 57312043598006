import { __ } from '@wordpress/i18n';
import { ReactComponent as SearchIcon } from '../../assets/ico-search.svg';

const Keyword = ( { keyword, setKeyword, setCurrentPage } ) => {
  return  <div className="nua-search-filter__keyword">
            <input type="text" placeholder={ nsf.strings.search }value={keyword} onChange={e => { setKeyword(e.target.value); setCurrentPage(1); }} />
            <button type="submit" class="nua-search-filter__search-submit" aria-label="Search">
              <SearchIcon />
						</button>
          </div>;
}

export default Keyword;