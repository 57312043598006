import { __ } from '@wordpress/i18n';
import Dropdown from './Dropdown';

const Sort = ({ currentSort, sortOptions, setCurrentSort, setCurrentPage }) => {
  const sortLabels = {
    'date-desc': nsf.strings.date_desc,
    'date-asc': nsf.strings.date_asc,
    'title-asc': nsf.strings.title_asc,
    'title-desc': nsf.strings.title_desc,
    'event-upcoming': nsf.strings.event_upcoming,
    'event-ongoing': nsf.strings.event_ongoing,
    'event-past': nsf.strings.event_past,
  }

  return (
    <Dropdown
      items={sortOptions.map((sortOption) => ({slug: sortOption, label: sortLabels[sortOption]}))}
      identifier="sort"
      label={nsf.strings.sort}
      selectedItems={ currentSort ? {slug: currentSort, label: sortLabels[currentSort]} : null }
      handleClickItem={ (item) => {
        setCurrentSort(item.slug);
        setCurrentPage(1);
      } }
      handleClickClear={() => {
        setCurrentSort(null);
        setCurrentPage(1);
      }}
    />
  );
}

export default Sort;